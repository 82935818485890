import React, { Component } from "react";
import ReactDOM from "react-dom";

export default class ScopeOfWorkItemsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeCollections: [],
      isLoading: false,
      quoteVersion: null,
      quote: null,
      quoteVersionID: "",
      domain: "",
    };
  }

  toggleBulkButton(checkboxData) {
    console.log(checkboxData);
    $(".apply-button").removeAttr("disabled");
  }

  componentWillMount() {
    this.setState((preState) => {
      return {
        quoteVersion: JSON.parse(this.props.quote_version),
        quote: JSON.parse(this.props.quote),
        quoteVersionID:
          window.location.href.split("/")[
            window.location.href.split("/").length - 1
          ],
        domain: window.location.hostname,
      };
    });
  }

  componentDidMount() {
    axios.get(`/api/proposal/${this.state.quoteVersionID}`).then((res) => {
      let tempArr = [];
      res.data.scope_collections.forEach((scopeCollection) =>
        tempArr.push(scopeCollection)
      );

      this.setState((preState) => {
        return {
          scopeCollections: tempArr,
          quoteVersion: { ...res.data },
          isLoading: false,
        };
      });
    });
  }

  render() {
    let borderBottom = {
      borderBottom: "1px solid #cec9da",
    };
    let width20 = {
      width: "20%",
    };
    let width15 = {
      width: "15%",
    };
    let width10 = {
      width: "10%",
    };
    let width5 = {
      width: "5%",
    };
    let maxWidth70 = {
      maxWidth: "70px",
    };
    let minWidth32 = {
      minWidth: "32px",
    };

    const statusName = this.state.quoteVersion.quote_status.name;
    const colourName =
      (statusName == "Pending" || statusName == "Quote Sent") &&
      this.state.quote.job_id == null
        ? "Colour"
        : "";

    return (
      <div class="table-responsive" id="accordion">
        {this.state.scopeCollections.map((scopeCollection) => (
          <div key={scopeCollection.id}>
            <h4 class="mt-5 text-red">{scopeCollection.name}</h4>

            {scopeCollection.line_item_collections.map((lineItemCollection) => (
              <div key={lineItemCollection.id}>
                <div style={borderBottom} class="mb-1">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    class="panel-heading collapsed"
                    href={`#lineItemCollection_${lineItemCollection.id}`}
                  >
                    <h5 class="mt-4">{lineItemCollection.name}</h5>
                  </a>
                </div>

                <div
                  class="spec-table-wrap panel-collapse collapse"
                  id={`lineItemCollection_${lineItemCollection.id}`}
                >
                  <table class="table">
                    <thead>
                      <tr>
                        <th style={width20}>Name</th>
                        <th style={maxWidth70}>Width</th>
                        <th style={maxWidth70}>Height</th>
                        <th style={minWidth32}>QTY</th>
                        <th style={width15}>Unit Of Measure</th>
                        <th style={width10}>Specifications</th>
                        <th style={width20}>{colourName}</th>
                        <th style={width5}>
                          <input
                            type="checkbox"
                            data-collection-id={lineItemCollection.id}
                            class="group-select align-middle d-none ml-2"
                            value=""
                          />
                        </th>
                        <th style={width20}>Photos</th>
                      </tr>
                    </thead>

                    <tbody
                      class="line-item-list"
                      data-hash_id={this.state.quoteVersion.hash_id}
                    >
                      {lineItemCollection.line_items.map((lineItem) => (
                        <tr key={lineItem.id}>
                          <td>{lineItem.name}</td>
                          <td>{lineItem.width > 0 ? lineItem.width : ""}</td>
                          <td>{lineItem.height > 0 ? lineItem.height : ""}</td>
                          <td>{lineItem.quantity}</td>
                          <td>{lineItem.unit_of_measure.name}</td>
                          <td>
                            {lineItem.specifications.map((specification) => (
                              <span key={specification.id}>
                                {specification.code}
                              </span>
                            ))}
                          </td>
                          <td>
                            {colourName == "Colour" ? (
                              <div class="ui-widget">
                                <div class="d-inline-flex">
                                  <input
                                    name="line_item_colour[]"
                                    value={lineItem.colour}
                                    class={`form-control line_item_colour line_item-${lineItem.id}`}
                                    data-line_item_id={lineItem.id}
                                  />
                                  <label
                                    id={`match-text-${lineItem.id}`}
                                    class="match-text align-middle ml-2 d-none"
                                  >
                                    Match Existing
                                  </label>
                                  <label
                                    id={`colour-consult-text-${lineItem.id}`}
                                    class="colour-consult-text align-middle mr-2 d-none"
                                  >
                                    Colour Consultant
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <span>{lineItem.colour}</span>
                            )}
                          </td>
                          <td>
                            <input
                              id={`line-item-check-${lineItem.id}`}
                              type="checkbox"
                              class={`align-middle ml-2 d-none item-select-box select-box-${lineItem.id} group-${lineItemCollection.id}`}
                              data-collection-id={lineItemCollection.id}
                              data-select_box_id={lineItem.id}
                              onClick={this.toggleBulkButton.bind(this)}
                              value=""
                            />
                          </td>
                          <td>
                            <div class="slick-slider-photos">
                              {lineItem.photos.map((photo) => (
                                <div key={photo.id}>
                                  {photo.media_type == "image" ? (
                                    <a
                                      href={photo.url}
                                      data-caption={lineItem.name}
                                    >
                                      <img
                                        class="img-fluid"
                                        height="50"
                                        width="50"
                                        src={photo.url}
                                      />
                                    </a>
                                  ) : (
                                    <div
                                      class="video-lightbox"
                                      data-video={photo.url}
                                      data-caption={lineItem.name}
                                    >
                                      <video height="50" width="50">
                                        <source
                                          src={photo.url}
                                          type="video/mp4"
                                        >
                                          Your browser does not support the
                                          video tag.
                                        </source>
                                      </video>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

if (document.getElementById("scope-of-work-items-app")) {
  const element = document.getElementById("scope-of-work-items-app");
  const props = { ...element.dataset };

  ReactDOM.render(
    <ScopeOfWorkItemsApp {...props} />,
    document.getElementById("scope-of-work-items-app")
  );
}
